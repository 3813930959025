import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./StatsComponent.module.css"; // Import CSS module for custom styles
import stylesBanner from "./BannerSmall.module.scss";
import stylesEvents from "./Events.module.scss";
import { generateGradientTextStyle } from "./util/theme";

const StatsComponent = ({ data, theme }) => {

  return (
    <div
      className={`${stylesBanner["banner-small"]}`}
      style={{ backgroundColor: theme.style === "light" ? "white" : "black" }}
    >
      <div className={`${stylesBanner["container-xl-low-padding"]}`}>
        <h2
          className={`text-center text-white ${stylesBanner["banner-small-content-heading"]}`}
        >
          {data.title.map((item) => (
            <span
              key={item.id}
              className={`${item.className ? stylesEvents[item.className] : ''} ${theme.style === 'light' ? stylesEvents["text-black-color"] : ''}`}
              style={
                !item.className
                  ? generateGradientTextStyle(theme.gradient1, theme.gradient2)
                  : {}
              }
            >
              {item.text}
            </span>
          ))}
        </h2>
        <Container className="my-5">
          <Row className={`justify-content-center ${styles.customRow}`}>
            {data.points.map((item, index) => (
              <Col xs={12} sm={6} lg={4} key={index} className="mb-4">
                <div className={`p-3 text-center ${styles.customCard} ${theme.style === 'light' ? styles.customCardWhite : ''}`}>
                  <div
                    className={styles.imageOverlay}
                    style={{
                      backgroundImage: `url('https://toppernotes.s3.ap-south-1.amazonaws.com/images/nMR24IUHMzQdSD0Q3ssJ.svg')`,
                    }}
                  ></div>
                  <h3 className={`mb-3 ${styles.customHeading}`}>
                    {item.value}
                  </h3>
                  <p className={`text-white ${theme.style === 'light' ? stylesEvents["text-black-color"] : ''}`}
                  >
                    {item.description}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default StatsComponent;
