import React from "react";
// import Link from "next/link";
import styles from "./PageBanner.module.scss";
import styles2 from "../../pages/current-affairs/blog/[slug].module.scss";
import BC from "../../components/Question/Breadcrumb";

const PageBanner = ({ pageTitle, breadcrumb }) => {
  return (
    <div className={styles["page-title-area"]}>
      <div className={styles2.container}>
        <div className={styles["page-title-content"]}>
          {breadcrumb && (
            <div>
              <BC data={breadcrumb} />
            </div>
          )}
          <h1 className={styles["h1"]}>{pageTitle}</h1>
        </div>
      </div>
    </div>
  );
};

export default PageBanner;
