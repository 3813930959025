import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { PlayIcon } from "@heroicons/react/solid";
import stylesEvent from "./Events.module.scss";
import stylesBanner from "./BannerSmall.module.scss";
import { 
    generateGradientTextStyle,
    generateVideoTestimonialCardStyle
 } from "./util/theme";

const VideoTestimonials = ({ data, theme }) => {
  const [show, setShow] = useState(false);
  const [currentVideo, setCurrentVideo] = useState("");

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [show]);

  const handleShow = (videoUrl) => {
    setCurrentVideo(videoUrl);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setCurrentVideo("");
  };

  return (
    <div
      className={`${stylesBanner["banner-small"]}`}
    //   style={{ backgroundColor: theme.style === "light" ? "white" : "black" }}
    >
      <div className={`${stylesBanner["container-xl-low-padding"]}`}>
        <h2
          className={`text-center text-white ${stylesBanner["banner-small-content-heading"]}`}
        >
          {data.title.map((item) => (
            <span
              key={item.id}
              className={`${item.className ? stylesEvent[item.className] : ''} ${theme.style === 'light' ? stylesEvent["text-black-color"] : ''}`}
              style={
                !item.className
                  ? generateGradientTextStyle(theme.gradient1, theme.gradient2)
                  : {}
              }
            >
              {item.text}
            </span>
          ))}
        </h2>
        <div className="row align-items-center">
          <div className="container mt-4">
            <div
              className={`row ${data.style === "card" ? stylesEvent["custom-row-testimonials"] : ""}`}
            >
              {data.content.map((video, index) => (
                <div className="col-6 col-md-4 mb-3" key={index}>
                  {data.style === "image" ? (
                    <div
                      className={`${stylesEvent.aspectRatioWrapper}`}
                      onClick={() => handleShow(video.videoUrl)}
                    >
                      <img
                        src={video.thumbnail}
                        alt="Thumbnail"
                        className={`${stylesEvent.aspectRatioImage}`}
                        loading="lazy"
                      />
                      <h5 className={stylesEvent.videoTitle}>{video.title}</h5>
                      <div
                        className={`${stylesEvent["video-testimonial-image"]}`}
                        style={{ cursor: "pointer" }}
                      >
                        <PlayIcon className={stylesEvent.playIcon} />
                      </div>
                    </div>
                  ) : (
                    <div className={`${stylesEvent.testimonialWrapper} ${theme.style === "light" ? stylesEvent.testimonialWrapperLight : stylesEvent.testimonialWrapperDark}`}
                    style={{ '--highlight-color': theme.gradient1 }}>
                      <a
                        href="#"
                        className={stylesEvent.lightboxLink}
                        aria-label="open lightbox"
                        aria-haspopup="dialog"
                        onClick={(e) => {
                          e.preventDefault();
                          handleShow(video.videoUrl);
                        }}
                      >
                        <img
                          src={video.thumbnail}
                          alt="Thumbnail"
                          className={stylesEvent.imageFullSize}
                          loading="lazy"
                        />
                        <PlayIcon 
                        className={stylesEvent.playIcon}
                        style={{ color: theme.gradient1 }}/>
                      </a>
                      <div className={stylesEvent.textContainer}>
                        <div 
                        className={stylesEvent.nameText}
                        style={{ color: theme.style === 'light' ? '#000' : undefined }}
                        >
                            {video.name}
                            </div>
                        <div 
                        className={stylesEvent.postText}
                        style={{ color: theme.style === 'light' ? '#333' : undefined }}
                        >{video.post}</div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <Modal
              isOpen={show}
              onRequestClose={handleClose}
              contentLabel="Video Modal"
              className={stylesEvent.Modal}
              overlayClassName={stylesEvent.Overlay}
            >
              <div style={{ width: "100%", height: "600px" }}>
              <iframe style={{width: "100%", height: "100%", border: 0}} src={`${currentVideo}?autoplay=false&loop=false&muted=false&preload=false&responsive=true`} loading="lazy"  allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowFullScreen></iframe>
              </div>
              

              <button
                type="button"
                className={stylesEvent.primaryButton}
                onClick={handleClose}
              >
                Close
              </button>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoTestimonials;
