import React from "react";
import styles from "./Events.module.scss";

const EventDetails = ({ data, theme }) => {
  return (
    <div
      className={styles.details_component}
      style={{ backgroundColor: theme.bgcolorcontainer[theme.style] }}
    >
      <div className={styles.details_grid}>
        {data.eventChips.map((item, index) => (
          <div
            key={index}
            className={`${styles.details_grid_item} ${
              theme.style === "light" ? styles.details_grid_item_light : ""
            }`}
            style={{ backgroundColor: theme.bgcolorchip[theme.style] }}
          >
            <item.icon
              className={styles.custom_img_size}
              style={{ color: theme.gradient1 }}
              aria-label={item.alt}
            />
            <h4
              className={styles.details_grid_heading}
              style={{ color: theme.style === "light" ? "black" : "white" }}
            >
              {item.heading}
            </h4>
          </div>
        ))}
      </div>

      <div className={styles.details_wrapper}>
        <img
          src={data.influencerBannerImage}
          loading="lazy"
          alt="Profile Image"
          className={styles.details_image}
        />
        <div className={styles.details_content}>
          <h3
            className={
              theme.style === "light"
                ? styles.details_hero_author_detail_light
                : "text-white"
            }
          >
            {data.influencerName}
          </h3>
          <p
            className={
              theme.style === "light"
                ? styles.details_hero_author_detail_light
                : "text-white"
            }
          >
            {data.influencerShortBio}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
