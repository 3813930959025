import React from "react";
import { Breadcrumb } from "flowbite-react";
import { useRouter } from "next/router";

const PageBreadcrumb = ({ data }) => {
  const router = useRouter();

  return (
    <Breadcrumb className="mb-10 -mt-4">
      {data.map((obj, i) => (
        <Breadcrumb.Item
          onClick={() => {
            router.push(obj.href);
          }}
          className="cursor-pointer"
          key={i}
        >
          <div className="block md:hidden">
            {obj.title.slice(0, 10)}
            {obj.title.length > 10 && "..."}
          </div>
          <div className="hidden md:block">
            {obj.title.slice(0, 20)}
            {obj.title.length > 20 && "..."}
          </div>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default PageBreadcrumb;
