// components/Superpowers.js
import React from 'react';
import { TypePhase, useTypedSuperpower } from './useTypedSuperpower';
import styles from './TypingComponent.module.css';

const fallbackTypingComponent = [
  'Microsoft USA',
  'Google',
  'Walmart USA',
  'Flipkart',
  'Cred India',
  'ToppersNotes',
  'Gojek Singapore',
];

const TypingComponent = ({ data, theme }) => {
    const fallbackTypingComponent = data.text;
    
      const { typedSuperpower, selectedSuperpower, phase, resume } =
        useTypedSuperpower(fallbackTypingComponent);

  return (
    
    <h1
      className={`d-flex flex-column text-align-left display-3 ${styles.superpowerContainer}`}
      onClick={resume}
    >
      <span
        className={`${styles.superpowerText} ${phase !== TypePhase.Deleting ? styles.endCursor : ''} ${phase === TypePhase.Pausing ? styles.blinking : ''}`}
        aria-label={selectedSuperpower}
      >
        {typedSuperpower}
      </span>
    </h1>
  );
};

export default TypingComponent;