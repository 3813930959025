import React from "react";
import Link from "next/link";
import styles from "./Events.module.scss";
import stylesBanner from "./BannerSmall.module.scss";
import {
  generateGradientTextStyle,
  generateBannerSmallImgStyle,
} from "./util/theme";

const Bonuses = ({ data, theme }) => {
  return (
    <div
      className={`${stylesBanner["banner-small-img"]}`}
      style={{
        backgroundColor: theme.style === "light" ? "white" : "black",
        ...generateBannerSmallImgStyle(theme.gradient1),
      }}
    >
      <div className={`${stylesBanner["container-xl-low-padding"]}`}>
        <h2
          className={`text-center text-white ${stylesBanner["banner-small-content-heading"]}`}
        >
          {data.title.map((item) => (
            <span
              key={item.id}
              className={`${item.className ? styles[item.className] : ''} ${theme.style === 'light' ? styles["text-black-color"] : ''}`}
              style={
                !item.className
                  ? generateGradientTextStyle(theme.gradient1, theme.gradient2)
                  : {}
              }
            >
              {item.text}
            </span>
          ))}
        </h2>
        <div className="program-list">
          <div className="row">
            {data.content.map((bonus) => (
              <div className="col-lg-6 col-md-6" key={bonus.id}>
                {bonus.bonusImage ? (
                  <div className="bonus-container">
                    <h4 className={`${theme.style === 'light' ? 'text-black' : 'text-white'}`}>
                        {bonus.title}
                        </h4>
                    <img
                      src={bonus.bonusImage}
                      alt={bonus.title}
                      loading="lazy"
                      className="bonus-image"
                    />
                  </div>
                ) : (
                  <>
                    <div
                      className={`single-program-box ${bonus.bgImage ? "has-bg" : ""}`}
                      style={
                        bonus.bgImage
                          ? { backgroundImage: `url(${bonus.bgImage})` }
                          : {}
                      }
                    >
                      {bonus.icon && (
                        <div className="icon">
                          <i className={bonus.icon}></i>
                        </div>
                      )}
                      <h3>
                        <Link href={bonus.link}>
                          <a>{bonus.title}</a>
                        </Link>
                      </h3>
                      <p>{bonus.description}</p>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bonuses;
