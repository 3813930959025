import React from "react";
import styles from "./Events.module.scss";
import stylesBanner from "./BannerSmall.module.scss";
import {
  generateGradientTextStyle,
  generateAccordionToolsStyle,
} from "./util/theme";

const LearningTools = ({ data, theme }) => {
  return (
    <div
      className={`${stylesBanner["banner-small"]}`}
      style={{ backgroundColor: theme.style === "light" ? "white" : "black" }}
    >
      <div className={`${stylesBanner["container-xl"]}`}>
        <h2
          className={`text-center text-white ${stylesBanner["banner-small-content-heading"]}`}
        >
          {data.title.map((item) => (
            <span
              key={item.id}
              className={`${item.className ? styles[item.className] : ''} ${theme.style === 'light' ? styles["text-black-color"] : ''}`}
              style={
                !item.className
                  ? generateGradientTextStyle(theme.gradient1, theme.gradient2)
                  : {}
              }
            >
              {item.text}
            </span>
          ))}
        </h2>
        <div className={`${styles["learning-tools-block"]}`}>
          {data.content.map((tool, index) => (
            <div
              key={index}
              className="tools-wrap d-flex flex-column align-items-center"
            >
              <div
                className={`${styles["tools-img"]}`}
                style={generateAccordionToolsStyle(theme)}
              >
                <img
                  src={tool.img}
                  alt=""
                  width="100"
                  height="100"
                  loading="lazy"
                />
              </div>
              <div className={`${styles["tools-name"]} ${theme.style === 'light' ? 'text-black' : 'text-white'}`}>
                {tool.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LearningTools;
