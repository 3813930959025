import React, { useState } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBContainer,
  MDBIcon,
} from "mdb-react-ui-kit";
import styles from "./Events.module.scss";
import stylesBanner from "./BannerSmall.module.scss";
import { 
    generateGradientTextStyle,
    generateTimelineStyle,
    generateTimelineCardStyle } from "./util/theme";

export default function EventTimeline({ data, theme }) {
  // State to control how many items are visible
  const [visibleCount, setVisibleCount] = useState(data.visibilityLimit);

  // Function to handle "Load More" click
  const loadMore = () => {
    setVisibleCount(data.content.length); // Load 4 more items
  };

  return (
    <div
      className="pt-70 pb-100"
      style={{ backgroundColor: theme.style === "light" ? "white" : "black" }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-cg-5 col-md-12">
            <h2
              className={`text-center ${stylesBanner["banner-small-content-heading"]}`}
            >
              {data.title.map((item) => (
                <span
                  key={item.id}
                  className={`${item.className ? styles[item.className] : ''} ${theme.style === 'light' ? styles["text-black-color"] : ''}`}
                  style={
                    !item.className
                      ? generateGradientTextStyle(
                          theme.gradient1,
                          theme.gradient2
                        )
                      : {}
                  }
                >
                  {item.text}
                </span>
              ))}
            </h2>
          </div>

          <div className="timeline-container">
            <MDBContainer fluid className="py-5">
              <div
                className="main-timeline-2"
                style={generateTimelineStyle(theme.gradient1)}
              >
                {data.content.slice(0, visibleCount).map((event, index) => (
                  <div
                    key={index}
                    className={`timeline-2 ${index % 2 === 0 ? "left-2" : "right-2"}`}
                    style={generateTimelineStyle(theme.gradient1)}
                  >
                    <MDBCard
                    className="timeline_card position-relative"
                    style={generateTimelineCardStyle(theme)}>
                      <div
                        style={{
                          position: "absolute",
                          top: "-3%",
                          left: "5%",
                          borderRadius: "8px",
                          padding: "12px",
                          fontWeight: "800",
                          fontSize: "16px",
                          color: `${theme.textColorButton}`,
                          minWidth: "80px",
                          textAlign: "center",
                          background: `linear-gradient(127.62deg, ${theme.gradient1} 26%, ${theme.gradient2})`,
                          letterSpacing: "1px",
                          textTransform: "uppercase",
                        }}
                      >
                        {event.timeline}
                      </div>
                      <div className="card-img-container">
                        {event.imageUrl && event.imageUrl.length > 5 ? (
                          <MDBCardImage
                            src={event.imageUrl}
                            alt="Responsive image"
                            loading="lazy"
                            position="top"
                            style={{
                              "--background-color": theme.imageFillColor || "white",
                            }}
                            className={styles.timelineCardImage}
                          />
                        ) : null}
                      </div>
                      <MDBCardBody
                        className="p-4"
                        style={{
                          marginTop:
                            event.imageUrl && event.imageUrl.length > 5
                              ? "unset"
                              : "2rem",
                        }}
                      >
                        <div className={`${theme.style === "light" ? styles.customCardBodyLight : styles.customCardBody}`}>
                        <h4 className="fw-bold mb-4">
                          {event.title}
                        </h4>
                        <div
                          className="mb-0"
                          dangerouslySetInnerHTML={{
                            __html: event.description,
                          }}
                        ></div>
                        </div>
                      </MDBCardBody>
                    </MDBCard>
                  </div>
                ))}
              </div>
              {data.content.length > visibleCount && (
                <div className="text-center">
                  <button
                    onClick={loadMore}
                    className={`${styles.loadMoreButton} ${styles.widgetButton}`}
                  >
                    Load More
                  </button>
                </div>
              )}
            </MDBContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
