import React, { useEffect, useRef, useState} from "react";
import styles from "./Events.module.scss";
import TypingComponent from '../programs/TypingComponent';
import { generateGradientTextStyle } from "./util/theme";

const TypingHeading = ({ data, theme }) => {
    return (
      <div className={`${styles.header_heading}`}>
        
        {data.map((part, index) => (
          Array.isArray(part.text) ? (
            console.log(`Array found at index ${index}:`, part),
            <TypingComponent
              key={index}
              data={part}
              theme={theme}
            />
          ) : (
            // console.log(`String found at index ${index}:`, part),
            <span
              key={index}
              className={`${part.className ? styles[part.className] : ""} ${theme.style === "light" ? styles["text-black-color"] : ""}`}
              style={
                !part.className
                  ? { ...generateGradientTextStyle(theme.gradient1, theme.gradient2), fontWeight: 600 }
                  : { fontWeight: 50 }
              }
            >
              {part.text}
            </span>
          )
        ))}
        <br />
      </div>
    );
  };
  
  export default TypingHeading;