import React from "react";
import styles from "./Mentors.module.css";
import stylesEvent from "./Events.module.scss";
import stylesBanner from "./BannerSmall.module.scss";
import {
  generateGradientTextStyle,
  generateMentorStyle,
  generateCompanyIconStyle,
} from "./util/theme";

const Mentors = ({ data, theme }) => {
  return (
    <div
      className={`${stylesBanner["banner-small"]}`}
      style={{ backgroundColor: theme.style === "light" ? "white" : "black" }}
    >
      <div className={`${stylesBanner["container-xl-low-padding"]}`}>
        <h2
          className={`text-center text-white ${stylesBanner["banner-small-content-heading"]}`}
        >
          {data.title.map((item) => (
            <span
              key={item.id}
              className={`${item.className ? stylesEvent[item.className] : ''} ${theme.style === 'light' ? stylesEvent["text-black-color"] : ''}`}
              style={
                !item.className
                  ? generateGradientTextStyle(theme.gradient1, theme.gradient2)
                  : {}
              }
            >
              {item.text}
            </span>
          ))}
        </h2>
        <div className="row align-items-center">
          <div className="container mt-4">
            <div className={`row ${stylesEvent["custom-row-mentors"]}`}>
              {data.content.map((mentor, index) => (
                <div className="col-6 col-md-4 mb-3" key={index}>
                  <div
                    className={`${styles.mentorItem} ${mentor.big ? styles.mentorBigItem : ""}`}
                    style={generateMentorStyle(theme.style, theme.gradient1)}
                  >
                    <div className={styles.mentorImage}>
                      <img
                        src={mentor.image}
                        alt={mentor.name}
                        className={styles.imageFitCover}
                        loading="lazy"
                      />
                    </div>
                    <div className={styles.mentorContainer}>
                      <div className={styles.mentorContent}>
                        <div className={`${styles.mentorName} ${theme.style === 'light' ? stylesEvent["text-dark-colors"] : ''}`}
                        >
                            {mentor.name}
                        </div>
                        <div className={`${styles.mentorpostName} ${theme.style === 'light' ? stylesEvent["text-dark-colors"] : ''}`}>
                          {mentor.post}
                        </div>
                      </div>
                      <div
                        className={styles.changeMcp}
                        style={generateCompanyIconStyle(theme.style, theme.gradient1)}
                      >
                        <div className={styles.companyIconContainer}>
                          {mentor.companyIcons.map((icon, index) => (
                            <img
                              key={index}
                              src={icon}
                              alt="Company Icon"
                              loading="lazy"
                              className={styles.companyIcon}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mentors;
