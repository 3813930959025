import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import styles from "./Curriculum.module.css"; // Import the CSS file
import stylesEvent from "./Events.module.scss"; // Import the CSS file
import stylesBanner from "./BannerSmall.module.scss";
import {
  generateGradientTextStyle,
  generateAccordionHeaderStyle,
  generateAccordionBodyStyle,
  generateAccordionChipStyle,
} from "./util/theme";

// Dictionary to store the accordion items

export default function Curriculum({ data, theme }) {
  const [expandedItems, setExpandedItems] = useState(data.expandedKeys);

  const handleToggle = (expanded) => {
    setExpandedItems(expanded);
  };

  return (
    <div
      className={`${stylesBanner["banner-small"]}`}
      style={{ backgroundColor: theme.style === "light" ? "white" : "black" }}
    >
      <div
        className={`${stylesBanner["container-xl-low-padding"]} ${stylesBanner["container-max-width"]}`}
      >
        <h2
          className={`text-center text-white ${stylesBanner["banner-small-content-heading"]}`}
        >
          {data.title.map((item) => (
            <span
              key={item.id}
              className={`${item.className ? stylesEvent[item.className] : ''} ${theme.style === 'light' ? stylesEvent["text-black-color"] : ''}`}
              style={
                !item.className
                  ? generateGradientTextStyle(theme.gradient1, theme.gradient2)
                  : {}
              }
            >
              {item.text}
            </span>
          ))}
        </h2>
        <div className="row g-4 my-4">
          <Accordion
            allowZeroExpanded
            preExpanded={expandedItems}
            onChange={handleToggle}
            style={{border: "none"}}
          >
            {data.content.map((item) => (
              <AccordionItem
                className={styles["accordion-item-custom"]}
                key={item.key}
                uuid={item.key}
              >
                <AccordionItemHeading>
                  <AccordionItemButton
                    className={`${styles["accordion-button-custom"]} ${styles["curriculum-title-gradient"]}`}
                    style={generateAccordionHeaderStyle(theme)}
                  >
                    <div
                      className={`${styles["module-text"]} ${styles["pm"]} ${styles["ai"]}`}
                      style={generateAccordionChipStyle(theme)}
                    >
                      {item.title}
                    </div>
                    {item.header}
                    <div className={styles["arrow-container"]}>
                      <span
                        className={`${styles["arrow"]} ${expandedItems.includes(item.key) ? styles["open"] : ""}`}
                      />
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel
                  className={`${styles["accordion-body-custom"]} ${theme.style === "light" ? styles["accordion-body-custom-light"] : ""} ${expandedItems.includes(item.key) ? "" : "accordion__panel--hidden"}`}
                  style={generateAccordionBodyStyle(theme)}
                  dangerouslySetInnerHTML={{ __html: item.body }}
                />
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
}
