import React from "react";
import styles from "./Events.module.scss"; // Assuming you've created a CSS module for styling
import stylesBanner from "./BannerSmall.module.scss";
import { 
    generateGradientTextStyle,
    generateEventAttendeeStyle
 } from "./util/theme";

const EventsTargets = ({ data, theme }) => {
  return (
    <div
      className={`${stylesBanner["banner-small"]}`}
      style={{ backgroundColor: theme.style === "light" ? "white" : "black" }}
    >
      <div className={`${stylesBanner["container-xl-low-padding"]}`}>
        <h2
          className={`text-center text-white ${stylesBanner["banner-small-content-heading"]}`}
        >
          {data.title.map((item) => (
            <span
              key={item.id}
              className={`${item.className ? styles[item.className] : ''} ${theme.style === 'light' ? styles["text-black-color"] : ''}`}
              style={
                !item.className
                  ? generateGradientTextStyle(theme.gradient1, theme.gradient2)
                  : {}
              }
            >
              {item.text}
            </span>
          ))}
        </h2>
        <div className="row g-4 my-4">
          {data.content.map((event, index) => (
            <div className="col-md-6" key={index}>
              <div 
              className="checkmarks_item d-flex align-items-start"
              style={generateEventAttendeeStyle(theme)}
              >
                <img
                  src="https://assets-global.website-files.com/5fdb2866020c200cd7fd7369/61613e3e518d5e1b210a631c_checkmark-icon.svg"
                  alt="Tick Icon"
                  width={40}
                  height={40}
                  loading="lazy"
                  className="me-3"
                />
                <div className={`${theme.style === 'light' ? 'text-container-light' : 'text-container'}`}>
                  <h4
                    className={`mb-0 ${theme.style === 'light' ? 'text-black' : 'text-white'}`}
                    style={{ fontWeight: "bold", textAlign: "left" }}
                  >
                    {event.person_type}
                  </h4>
                  <div
                    className="mt-2 text-left"
                    dangerouslySetInnerHTML={{ __html: event.purpose }}
                  ></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventsTargets;
