import React from "react";
import Image from "next/image";
import Link from "next/link";
import styles from "./Events.module.scss";
import stylesBanner from "./BannerSmall.module.scss";
import { 
    generateGradientTextStyle,
    generateEventNonAttendeeStyle
 } from "./util/theme";

const EventsNotTargets = ({ data, theme }) => {
  return (
    <div
      className={`${stylesBanner["banner-small"]}`}
      style={{ backgroundColor: theme.style === "light" ? "white" : "black" }}
    >
      <div className={`${stylesBanner["container-xl-low-padding"]}`}>
        <h2
          className={`text-center text-white ${stylesBanner["banner-small-content-heading"]}`}
        >
          {data.title.map((item) => (
            <span
              key={item.id}
              className={`${item.className ? styles[item.className] : ''} ${theme.style === 'light' ? styles["text-black-color"] : ''}`}
              style={
                !item.className
                  ? generateGradientTextStyle(theme.gradient1, theme.gradient2)
                  : {}
              }
            >
              {item.text}
            </span>
          ))}
        </h2>
        <div className="row g-4">
          {data.content.map((item, index) => (
            <div className="col-md-6" key={index}>
              <div className="not_checkmarks_item d-flex align-items-start"
              style={generateEventNonAttendeeStyle(theme)}
              >
                <img
                  src="https://d14a823tufvajd.cloudfront.net/app_assets/svg/cross-svgrepo-com.svg"
                  alt="Tick Icon"
                  width={40}
                  loading="lazy"
                  className="me-3"
                />
                <div className={`${theme.style === 'light' ? 'text-container-light' : 'text-container'}`}>
                  <h4 
                  className={`mb-0 ${theme.style === 'light' ? 'text-black' : 'text-white'}`}
                  style={{ textAlign: "left" }}>
                    {item}
                  </h4>
                  {/* <p className="mt-2 text-left text-white">Learn the foundations of investing while you're young. This will give you a head start before you start earning money.</p> */}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center mt-4">
          {/* Include payment button or any additional content */}
        </div>
      </div>
    </div>
  );
};

export default EventsNotTargets;
