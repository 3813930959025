import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import styles from "./Events.module.scss";
import {
  generateRegisterButtonStyle,
  generateTextGradientStyle,
} from "./util/theme";
import moment from 'moment';
import { getCheckoutLink } from "../../lib/utils";
// import { navigateTocheckout } from "../../lib/utils";

const formatDateTime = (dateTimeStr) => {
  return moment(dateTimeStr, 'DD/MM/YYYY HH:mm:ss').format("Do MMMM, h:mm A");
};

const JoinEventButton = ({ data, theme, isTextWhite, slug }) => {
  const router = useRouter();
  const navigateTocheckout = () => {
    let checkoutLink = getCheckoutLink();
    if(!checkoutLink) {
      checkoutLink = "/event-checkout/" + slug;
    }
    router.push(checkoutLink);
  }

  // console.log(data, isTextWhite)
  return (
    <div className="text-center mt-2">
      <p className={`mt-3 mb-6 ${isTextWhite ? "text-white" : ""}`}>
        Reserve a seat before
        <span
          className={styles["text-gradient-color"]}
          style={generateTextGradientStyle(theme.gradient1, theme.gradient2)}
        >
          {" "}
          {formatDateTime(data.registerTill)}{" "}
        </span>{" "}
        to unlock Bonuses worth ₹{data.bonusesWorth}
      </p>
        <a onClick={navigateTocheckout} href='#'
          className={`${styles["button_freelancing"]} ${styles["glare-btn"]}`}
          style={generateRegisterButtonStyle(
            theme.gradient1,
            theme.gradient2,
            theme.textColorButton
          )}
        >
          <div>
            {data.joinButtonLabel} ₹{data.salePrice}
            <span className="text-decoration-line-through">
              {" "}
              ₹{data.originalPrice}
            </span>
          </div>
          <div className={styles.glare}></div>
        </a>
    </div>
  );
};

JoinEventButton.propTypes = {
  data: PropTypes.shape({
    joinButtonLabel: PropTypes.string.isRequired,
  }).isRequired,
  isTextWhite: PropTypes.bool,
};

// Set default props
// JoinEventButton.defaultProps = {
//   isTextWhite: true,
// };

export default JoinEventButton;
