import React from "react";
import SwiperCore, { Autoplay, Pagination, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import styles from "./Events.module.scss"; // Assuming you've created a CSS module for styling
import stylesBanner from "./BannerSmall.module.scss";
import { generateGradientTextStyle } from "./util/theme";

SwiperCore.use([Autoplay, Pagination, Lazy]);

const ImageTestimonials = ({ data, theme }) => {
  return (
    <div
      className={`${stylesBanner["banner-small"]}`}
    //   style={{ backgroundColor: theme.style === "light" ? "white" : "black" }}
    >
      <div className={`${stylesBanner["container-xl"]}`}>
        <h2
          className={`text-center text-white ${stylesBanner["banner-small-content-heading"]}`}
        >
          {data.title.map((item) => (
            <span
              key={item.id}
              className={`${item.className ? styles[item.className] : ''} ${theme.style === 'light' ? styles["text-black-color"] : ''}`}
              style={
                !item.className
                  ? generateGradientTextStyle(theme.gradient1, theme.gradient2)
                  : {}
              }
            >
              {item.text}
            </span>
          ))}
        </h2>
        <div className="row align-items-center">
          <div className="col-lg-12 col-md-12">
            <div className="feedback-content text-center">
              <div className={styles["testimonials-wrapper"]}>
                {data.content.map((testimonial, index) => (
                  <div className={styles["testimonial-item"]} key={index}>
                    <img
                      className={`img-fluid ${styles["testimonial-image"]}`}
                      src={testimonial.image}
                      loading="lazy"
                      alt={`Testimonial ${index + 1}`}
                      style={{ borderRadius: "20px" }}
                    />
                  </div>
                ))}
              </div>
              <Swiper
                spaceBetween={30}
                slidesPerView={1}
                autoplay={{ delay: 4000 }}
                speed={2000}
                lazy={true}
                pagination={{ clickable: true }}
                breakpoints={{
                  620: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1080: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                className={styles["swiper"]}
              >
                {data.content.map((testimonial, index) => (
                  <SwiperSlide key={index}>
                    <img
                      className={`swiper-lazy img-fluid ${styles["testimonial-image"]}`}
                      src={testimonial.image}
                      alt={`Testimonial ${index + 1}`}
                      loading="lazy"
                      style={{ borderRadius: "20px", margin: "0 auto" }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageTestimonials;
