import React from "react";
import Link from "next/link";
import styles from "./Events.module.scss"; // Import the CSS file
import stylesBanner from "./BannerSmall.module.scss";
import EventsTimer from "../SingleEvents/EventsTimer";
import JoinEventButton from "./JoinEventButton";
import { generateGradientTextStyle } from "./util/theme";

const EventTimerJoinWidget = ({ data, theme, slug }) => {
  return (
    <div className="coming-soon-area">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="coming-soon-content">
            <h2
              className={`text-left ${stylesBanner["banner-small-content-heading"]}`}
            >
              {data.title.map((item) => (
                <span
                  key={item.id}
                  className={`${item.className ? styles[item.className] : ''} ${theme.style === 'light' ? styles["text-black-color"] : ''}`}
                  style={
                    !item.className
                      ? generateGradientTextStyle(
                          theme.gradient1,
                          theme.gradient2
                        )
                      : {}
                  }
                >
                  {item.text}
                </span>
              ))}
            </h2>

            <EventsTimer data={data} />
            <div className="px-4 pb-4">
              <JoinEventButton data={data} theme={theme} isTextWhite={false} slug={slug}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventTimerJoinWidget;
