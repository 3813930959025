import React, { useEffect, useRef, useState} from "react";
import Link from "next/link"; // Import from 'next/link' for Next.js projects
import PropTypes from "prop-types";
import PageBanner from "../Common/PageBanner";
import EventDetails from "./EventDetails";
import styles from "./Events.module.scss";
import JoinEventButton from "./JoinEventButton";
import TypingHeading from './TypingHeading';
import { generateGradientTextStyle } from "./util/theme";

const HeroBanner = ({ data, theme, slug }) => {

  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
    const iframeRef = useRef(null);
    useEffect(() => {
        const handleLoad = () => {
          if (iframeRef.current) {
            iframeRef.current.src = `${data.videoUrl}?autoplay=true&responsive=true&muted=false`;

            setTimeout(() => {
              setIsIframeLoaded(true);
              // iframeRef.current.contentWindow.postMessage('unmute', 'https://iframe.mediadelivery.net');
            }, 1000);
          }
        };
    
        window.addEventListener("load", handleLoad);
    
        return () => {
          window.removeEventListener("load", handleLoad);
        };
      }, [data.videoUrl]);

  const {
    title,
    description,
    videoId,
    backgroundImageUrl,
    eventDetails,
    eventChips,
  } = data;
  const isTextWhite = theme.style !== "light";
  //   console.log(eventDetails, title)

//   const iframeRef = useRef(null);

  /*useEffect(() => {
    const iframe = iframeRef.current;
    
    const handleIframeMessage = (event) => {
      if (event.origin !== 'https://iframe.mediadelivery.net') {
        return;
      }

      console.log(JSON.parse(event.data));

      setTimeout(() => {
        // iframe.contentWindow.postMessage('play', 'https://iframe.mediadelivery.net');
        iframe.contentWindow.postMessage('unmute', 'https://iframe.mediadelivery.net');
      }, 1000);
    };

    window.addEventListener('message', handleIframeMessage);

    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);*/
  
  return (
    <div className={`${styles["section-header"]} ${styles["is-home-header"]}`}>
      <div
        className={`container-fluid text-white py-5`}
        style={{
          backgroundColor: theme.style === "light" ? "white" : "initial",
        }}
      >
        <div className="container">
          <TypingHeading data = {data.typingTitle} theme = {theme}/>
          <p
            className={`${styles.header_paragraph}`}
          >
            {data.description.map((part, index) => (
              <span
                key={index}
                className={`${part.className ? styles[part.className] : ""} ${theme.style === "light" ? styles["text-black-color"] : ""}`}
                style={
                    !part.className
                      ? { ...generateGradientTextStyle(
                        theme.gradient1, theme.gradient2
                        ), fontWeight: 900}
                      : { fontWeight: 200 }
                  }
              >
                {part.text}
              </span>
            ))}
          </p>
          <div className="row align-items-center text-center text-md-left">
            <div className={styles.header_grid}>
              <div className={styles.display_on_bigger_screens}>
                <EventDetails data={data.eventDetails} theme={theme} />
              </div>
              <div className="text-center">
                <div style={{ position: "relative", paddingTop: "56.25%" }}>
                  {/* show image based on isIframeLoaded */}

                  {!isIframeLoaded && <img src={data.videoThumbnailUrl} alt={title} style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: "cover", objectPosition: "center"}}/>}
                  
                  <iframe ref={iframeRef} src="" loading="lazy" style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%", border: 0}} allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowFullScreen></iframe>
                
                </div>
                <JoinEventButton
                  data={data.eventDetails}
                  theme={theme}
                  isTextWhite={isTextWhite}
                  slug={slug}
                />
              </div>
              <div className={styles.display_on_smaller_screens}>
                <EventDetails data={data.eventDetails} theme={theme} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;
