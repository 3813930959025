// Importing necessary components and styles
import Image from "next/image";
import styles from "./Events.module.scss"; // Assuming you've created a CSS module for styling
import stylesBanner from "./BannerSmall.module.scss";
import { generateGradientTextStyle } from "./util/theme";

const BrandsImpacted = ({ data, theme }) => {
  return (
    <div
      className={`${stylesBanner["banner-small"]}`}
      style={{ backgroundColor: theme.style === "light" ? "white" : "black" }}
    >
      <div className={`${stylesBanner["container-xl-low-padding"]}`}>
        <h2
          className={`text-center text-white ${stylesBanner["banner-small-content-heading"]}`}
        >
          {data.title.map((item) => (
            <span
              key={item.id}
              className={`${item.className ? styles[item.className] : ''} ${theme.style === 'light' ? styles["text-black-color"] : ''}`}
              style={
                !item.className
                  ? generateGradientTextStyle(theme.gradient1, theme.gradient2)
                  : {}
              }
            >
              {item.text}
            </span>
          ))}
        </h2>
        <div className={`${styles.BrandsImpactedLogoContainer}`}
        style={{ backgroundColor: theme.style === "light" ? "white" : "black" }}>
          {data.content.map((logo, index) => (
            <div
              key={index}
              className={`d-flex justify-content-center align-items-center ${styles.BrandsImpactedLogo} ${theme.style === 'light' ? styles.BrandsImpactedLogoLight : ''}`}
            >
              <img
                src={logo.src}
                alt={logo.alt}
                loading="lazy"
                style={{
                  maxWidth: "84px",
                  maxHeight: "30px",
                  filter: "grayscale(100%)",
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BrandsImpacted;
