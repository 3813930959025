import React from "react";
import dynamic from "next/dynamic";
import styles from "./Events.module.scss";
import stylesBanner from "./BannerSmall.module.scss";
import { generateGradientTextStyle } from "./util/theme";
const ModalVideo = dynamic(import("react-modal-video"));

const AboutArea = ({ data, theme }) => {
  const [display, setDisplay] = React.useState(false);

  React.useEffect(() => {
    setDisplay(true);
  }, []);

  const [isOpen, setIsOpen] = React.useState(true);
  const openModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`${stylesBanner["banner-small"]}`}
      style={{ backgroundColor: theme.style === "light" ? "white" : "black" }}
    >
      <div className={`${stylesBanner["container-xl-low-padding"]}`}>
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12">
            <div>
              <h2
                className={`text-left ${stylesBanner["banner-small-content-heading"]}`}
              >
                {data.title.map((item) => (
                  <span
                    key={item.id}
                    className={`${item.className ? styles[item.className] : ""} ${theme.style === "light" ? styles["text-black-color"] : ""}`}
                    style={
                      !item.className
                        ? generateGradientTextStyle(
                            theme.gradient1,
                            theme.gradient2
                          )
                        : {}
                    }
                  >
                    {item.text}
                  </span>
                ))}
              </h2>
              <div
                className={`${styles.whiteText} ${theme.style === "light" ? styles["text-black-color"] : ""}`}
                dangerouslySetInnerHTML={{ __html: data.bio }}
              />
            </div>
          </div>

          <div className="col-lg-7 col-md-12">
            <div className="about-video-box">
              {data.video ? (
                <iframe
                  className="embed-responsive-item"
                  src={data.video}
                  allowFullScreen
                  allow="autoplay; fullscreen; picture-in-picture"
                  style={{ width: "100%", height: "300px" }}
                ></iframe>
              ) : (
                <div>
                  <div
                    className={styles.backgroundContainer}
                    style={{ backgroundImage: `url(${data.image})` }}
                  ></div>
                  <div className={styles.overlayImageContainer}>
                    <img
                      src={data.image}
                      alt="About Image"
                      className={styles.centeredImage}
                      loading="lazy"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="divider"></div>
      <div className="shape3">
        <img src="/images/shape3.png" alt="image" loading="lazy" />
      </div>
      <div className="shape4">
        <img src="/images/shape4.png" alt="image" loading="lazy" />
      </div>
      <div className="shape2">
        <img src="/images/shape2.png" alt="image" loading="lazy" />
      </div>

      {/* Uncomment and update videoId if you want to enable modal video */}
      {/* {display ? (
        <ModalVideo
          channel="youtube"
          isOpen={!isOpen}
          videoId="bk7McNUjWgw"
          onClose={() => setIsOpen(!isOpen)}
        />
      ) : (
        ""
      )} */}
    </div>
  );
};

export default AboutArea;
