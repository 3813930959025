// components/useTypedSuperpower.js
import { useCallback, useEffect, useState } from 'react';

export const TypePhase = {
  Typing: 'Typing',
  Pausing: 'Pausing',
  Deleting: 'Deleting',
};

const TYPING_INTERVAL_MIN = 40;
const TYPING_INTERVAL_MAX = 80;
const TYPING_PAUSE_MS = 1500;
const DELETING_INTERVAL = 30;
const DELETING_PAUSE_MS = 200;

const getRandomTypingInterval = () =>
  Math.floor(Math.random() * (TYPING_INTERVAL_MAX - TYPING_INTERVAL_MIN + 1)) +
  TYPING_INTERVAL_MIN;

export const useTypedSuperpower = (superpowers) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [phase, setPhase] = useState(TypePhase.Typing);
  const [typedSuperpower, setTypedSuperpower] = useState('');

  const resume = useCallback(() => {
    if (phase !== TypePhase.Pausing) return;
    setPhase(TypePhase.Deleting);
  }, [phase]);

  useEffect(() => {
    switch (phase) {
      case TypePhase.Typing: {
        const nextTypedSuperPower = superpowers[selectedIndex].slice(
          0,
          typedSuperpower.length + 1
        );

        if (nextTypedSuperPower === typedSuperpower) {
          setPhase(TypePhase.Pausing);
          return;
        }

        const timeout = setTimeout(() => {
          setTypedSuperpower(nextTypedSuperPower);
        }, getRandomTypingInterval());

        return () => clearTimeout(timeout);
      }
      case TypePhase.Deleting: {
        if (!typedSuperpower) {
          const timeout = setTimeout(() => {
            const nextIndex = selectedIndex + 1;
            setSelectedIndex(superpowers[nextIndex] ? nextIndex : 0);
            setPhase(TypePhase.Typing);
          }, DELETING_PAUSE_MS);
          return () => clearTimeout(timeout);
        }

        const nextRemaining = superpowers[selectedIndex].slice(
          0,
          typedSuperpower.length - 1
        );

        const timeout = setTimeout(() => {
          setTypedSuperpower(nextRemaining);
        }, DELETING_INTERVAL);

        return () => clearTimeout(timeout);
      }
      case TypePhase.Pausing:
      default:
        const timeout = setTimeout(() => {
          setPhase(TypePhase.Deleting);
        }, TYPING_PAUSE_MS);

        return () => clearTimeout(timeout);
    }
  }, [superpowers, typedSuperpower, selectedIndex, phase]);

  return {
    typedSuperpower,
    phase,
    resume,
    selectedSuperpower: superpowers[selectedIndex],
  };
};
